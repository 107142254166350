import api from '../library/axios';

export default {
  getTemplateList(workspace_id, channel_id) {
    return api({
      method: 'get',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}`,
    });
  },
  getTemplateDetail(workspace_id, channel_id, template_id) {
    return api({
      method: 'get',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}/${template_id}`,
    });
  },
  updateTemplateDetail({
    workspace_id,
    channel_id,
    template_id,
    body,
  }) {
    return api({
      method: 'patch',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}/${template_id}`,
      data: body,
    });
  },
  uploadTemplateMedia(formData, workspace_id, channel_id) {
    return api({
      method: 'post',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}/media`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteTemplate({
    workspace_id, channel_id, template_name,
  }) {
    return api({
      method: 'delete',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}`,
      data: {
        template_name,
      },
    });
  },
  syncTemplate({
    workspace_id, channel_id, company_id, template_id,
  }) {
    return api({
      method: 'patch',
      url: `/api/broadcast/sync_templates/${workspace_id}/${channel_id}/${company_id}`,
      data: {
        template_id,
      },
    });
  },
  uploadRecipent(
    formData, workspace_id, channel_id,
  ) {
    return api({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'put',
      url: `/api/broadcast/recipients/${workspace_id}/${channel_id}`,
      data: formData,
    });
  },
  sendBroadcast({
    workspace_id,
    form,
  }) {
    return api({
      method: 'post',
      url: `/api/broadcast/messages/${workspace_id}`,
      data: form,
    });
  },
  submitRequestTemplate({
    workspace_id,
    channel_id,
    templates,
    groups_id,
  }) {
    return api({
      method: 'post',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}`,
      data: {
        templates,
        groups_id,
      },
    });
  },
  getSchedule(workspace_id, params) {
    return api({
      method: 'get',
      url: `/api/broadcast/schedules/${workspace_id}`,
      params,
    });
  },
  cancelSchedule(workspace_id, batch_id) {
    return api({
      method: 'delete',
      url: `/api/broadcast/schedules/${workspace_id}/${batch_id}`,
    });
  },
  updateTemplateDivisions({
    workspace_id,
    channel_id,
    template_id,
    data,
  }) {
    return api({
      method: 'patch',
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}/${template_id}/divisions`,
      data,
    });
  },
};
